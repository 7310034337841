import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DatasetComponent } from './components/containers/dataset/dataset.component';
import { DatasetsComponent } from './components/containers/datasets/datasets.component';
import { HomeComponent } from './components/containers/home/home.component';
import { DatasetPreviewCardComponent } from './components/shared/dataset-preview/dataset-preview-list/dataset-preview-card/dataset-preview-card.component';
import { DatasetPreviewListComponent } from './components/shared/dataset-preview/dataset-preview-list/dataset-preview-list.component';
import { DatasetPreviewToolbarComponent } from './components/shared/dataset-preview/dataset-preview-list/dataset-preview-toolbar/dataset-preview-toolbar.component';
import { DatasetPreviewComponent } from './components/shared/dataset-preview/dataset-preview.component';
import { PartnerCardComponent } from './components/shared/partners/partners-list/partner-card/partner-card.component';
import { PartnersListComponent } from './components/shared/partners/partners-list/partners-list.component';
import { PartnersComponent } from './components/shared/partners/partners.component';
import { ItemCardComponent } from './components/shared/preview-list/item-card/item-card.component';
import { PreviewListComponent } from './components/shared/preview-list/preview-list.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { DropdownComponent } from './components/ui/dropdown/dropdown.component';
import { InputComponent } from './components/ui/input/input.component';
import { DatasetFilterPipe } from './pipes/dataset-filter.pipe';
import { ContentTypeBadgeComponent } from './components/shared/content-type-badge/content-type-badge.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DatasetPreviewComponent,
    DatasetPreviewListComponent,
    DatasetPreviewCardComponent,
    ButtonComponent,
    PartnersComponent,
    PartnersListComponent,
    PartnerCardComponent,
    PreviewListComponent,
    ItemCardComponent,
    DatasetComponent,
    DatasetsComponent,
    DatasetPreviewToolbarComponent,
    DropdownComponent,
    InputComponent,
    ContentTypeBadgeComponent,
    DatasetFilterPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
