<section class="dataset-preview">
  <div class="component-content">
    <div class="dataset-preview__title">
      <div>
        <b [innerHTML]="numRecords"></b> records
      </div>
      <div class="dataset-preview__title-separator">
        -
      </div>
      <div>
        <b [innerHTML]="numDatasets"></b> datasets
      </div>
      <div class="dataset-preview__title-separator">
        -
      </div>
      <div>
        <b [innerHTML]="numSources"></b> sources
      </div>
    </div>
    <app-dataset-preview-list [showToolbar]="showToolbar" [limit]="datasetLimit"></app-dataset-preview-list>
    <div *ngIf="showButtonGroup" class="dataset-preview__button-group">
      <app-button preset="blue" link="/datasets">Full Dataset Gallery</app-button>
    </div>
  </div>
</section>