// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import Environment, { texts_judaica as texts } from 'src/environments/environment.interface';

const data_root = 'assets/data-judaica';

export const environment: Environment = {
  production: false,
  gAnaliticsId: '',
  texts: texts,
  datasetsFetchUrl: `${data_root}/datasets/index.json`,
  partnersFetchUrl: `${data_root}/partners/index.json`,
  partnersAboutFetchUrl: `${data_root}/partners-about/index.json`,
  datasetDetailsFetchUrl: `${data_root}/datasetDetails/index.json`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
