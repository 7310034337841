import { Component, OnInit } from '@angular/core';
import { DatasetFilterService } from 'src/app/services/dataset-filter.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { PartnerStore } from 'src/app/services/partner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  DATASET_LIMIT = 8;

  constructor(
    public partnerStore: PartnerStore,
    private env: EnvironmentService,
    private filter: DatasetFilterService
  ) {}

  ngOnInit(): void {
    this.filter.reset();
  }

  get headerTitle(): string {
    return this.env.texts.mainHeadline;
  }
}
